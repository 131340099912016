<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="d-flex flex-wrap flex-column align-items-center">
          <span class="ant-badge">
              <a :href="user_profile.profile_picture" target="_blank" download>
                 <span class="ant-avatar ant-avatar-lg ant-avatar-square ant-avatar-icon index_avatar_1A3j0">
                      <img :src="user_profile.profile_picture_url"  width="1em" height="1em">
                 </span>
              </a>
          </span>
          <a-spin :spinning="spinning" :delay="delayTime">
            <div class="text-center">
              <div class="text-dark font-weight-bold font-size-18"> {{user_profile.name}}</div>
              <div class="text-uppercase font-size-12"> {{user_profile.email}}</div>
              <div class="text-uppercase font-size-12 mb-3"> {{user_profile.phone_no}}</div>
              <div v-if="user_profile.hub" style="margin-bottom: 10px;">
                <h6 class="font-size-18" style="margin-bottom: 0px !important;">Hub</h6>
                <div class="font-size-14"> {{ user_profile.hub.hub_zone ? user_profile.hub.hub_zone.hub_zone_desc : ''}}</div>
                <div class="font-size-15"> {{ user_profile.hub ? user_profile.hub.name : ''}}</div>
              </div>
              <div v-if="user_profile.warehouse" style="margin-bottom: 10px;">
                <h6 class="font-size-18" style="margin-bottom: 0px !important;">Warehouse</h6>
                <div class="font-size-15"> {{ user_profile.warehouse ? user_profile.warehouse.name : ''}}</div>
              </div>
              <a class="btn btn-success btn-sm mr-3" href="javascript: void(0);" @click.prevent="profileEdit(user_profile.id), $bvModal.show('profile-update-modal')"><i class="fe fe-edit mr-1"></i>Profile Update</a>
              <a class="btn btn-warning btn-sm mr-3" href="javascript: void(0);" @click.prevent="$bvModal.show('password-update-modal')"><i class="fe fe-lock mr-1"></i>Password Update</a>
              <a v-if="user.role.includes('hub_in_charge') " class="btn btn-primary btn-sm mr-3" href="javascript: void(0);" @click.prevent="hubEdit(user_profile.id), $bvModal.show('hub-update-modal')"><i class="fe fe-edit mr-1"></i>Hub Info Update</a>
            </div>
          </a-spin>
        </div>
      </div>
    </div>
    <profile-update
      @getUser = getUser
      ref="updateUser"
    ></profile-update>
    <password-update
      @getUser = getUser
    ></password-update>
    <hub-update
      @getUser = getUser
      ref="updateHub"
    ></hub-update>
  </div>
</template>
<script>
import apiClient from '@/services/axios'
import profileUpdate from '@/views/userManagement/profile/profile-update'
import passwordUpdate from '@/views/userManagement/profile/password-update'
import hubUpdate from '@/views/userManagement/profile/hub-update'
import { mapGetters } from 'vuex'

export default {
  name: 'index',
  components: { profileUpdate, passwordUpdate, hubUpdate },
  data() {
    return {
      validation_errors: {},
      user_profile: {},
      update_user: {},
      loader: false,
      loading: false,
      show: false,
      spinning: false,
      delayTime: 500,
    }
  },
  computed: {
    ...mapGetters('user', ['user']),
  },
  mounted() {
    this.getUser()
  },
  methods: {
    getUser() {
      this.loading = true
      this.spinning = true
      apiClient.get('api/get/user/info').then(response => {
        this.loading = false
        this.spinning = false
        this.user_profile = response.data.user
      }).catch(error => {
        this.loading = false
        this.spinning = false
        console.log(error)
      })
    },
    profileEdit(userId) {
      this.$refs.updateUser.onUpdate(userId)
      this.$bvModal.show('profile-update-modal')
    },
    hubEdit() {
      this.$refs.updateHub.getHub()
      this.$bvModal.show('hub-update-modal')
    },
  },
}
</script>
