<template>
  <div>
    <div class="mb-5" ref="updateHub">
      <b-modal id="hub-update-modal" title="Hub Update" size="lg" hide-footer>
        <div v-show="show" class="alert alert-danger alert-dismissible fade show" role="alert">
          <span v-for="(err, index) in validation_errors" :key="index">{{ err[0] }} <br/></span>
        </div>
        <form action="" role="form">
          <div class="modal-content bg-white rounded">
          <div class="form-row">
            <div class="col-md-6 col-sm-6 col-lg-6">
              <label for="hub_employees" class="col-form-label col-form-label-sm">Hub Employees <span class="custom-required">*</span></label>
              <input type="text" v-model="hub.hub_employees" v-validate="'required|numeric'" id="hub_employees" name="hub_employees" class="form-control form-control-sm" placeholder="Enter hub employees"/>
              <div class="invalid-feedback">Hub employees is required</div>
            </div>
            <div class="col-md-6 col-sm-6 col-lg-6">
              <label for="rent_agreement_expiration" class="col-form-label col-form-label-sm">Rent Agreement Expiration <span class="custom-required">*</span></label>
              <datepicker :format="customRentAgreementDateFormat" v-model="hub.rent_agreement_expiration" v-validate="{ rules: { required:  true } }" :disabled-dates="disabledDates"  :class="errors.has('rent_agreement_expiration') ? 'error-border-color' : ''"  id="rent_agreement_expiration"  name="rent_agreement_expiration" placeholder="Select rent agreement expiration date" ></datepicker>
              <div class="invalid-feedback"> {{ errors.first('rent_agreement_expiration') }}</div>
            </div>
          </div>
            <div class="form-row">
              <div class="col-md-6 col-sm-6 col-lg-6">
                <label for="trade_license_expiration" class="col-form-label col-form-label-sm">Trade License Expiration <span class="custom-required">*</span></label>
                <select v-model="hub.trade_license_expiration" v-validate="'required'" id="trade_license_expiration" name="trade_license_expiration" class="form-control form-control-sm">
                  <option :value="''">Select trade license expiration</option>
                  <option value="1">Yes</option>
                  <option value="2">No</option>
                </select>
                <div class="invalid-feedback">Trade license expiration is required</div>
              </div>
              <div class="col-md-6 col-sm-6 col-lg-6">
                <label for="dife_license_expiration" class="col-form-label col-form-label-sm">DIFE License Expiration <span class="custom-required">*</span></label>
                <select v-model="hub.dife_license_expiration" v-validate="'required'" id="dife_license_expiration" name="dife_license_expiration" class="form-control form-control-sm">
                  <option :value="''">Select DIFE license expiration </option>
                  <option value="1">Yes</option>
                  <option value="2">No</option>
                </select>
                <div class="invalid-feedback">DIFE license expiration is required</div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-6 col-sm-6 col-lg-6">
                <label for="fire_license_expiration" class="col-form-label col-form-label-sm">Fire License Expiration <span class="custom-required">*</span></label>
                <select v-model="hub.fire_license_expiration" v-validate="'required'" id="fire_license_expiration" name="fire_license_expiration" class="form-control form-control-sm">
                  <option :value="''">Select fire license expiration</option>
                  <option value="1">Yes</option>
                  <option value="2">No</option>
                </select>
                <div class="invalid-feedback">Fire license expiration is required</div>
              </div>
              <div class="col-md-6 col-sm-6 col-lg-6">
                <label for="cleaning_company_id" class="col-form-label col-form-label-sm">Cleaning Company <span class="custom-required">*</span></label>
                <select v-model="hub.cleaning_company_id" v-validate="'required'" id="cleaning_company_id" name="cleaning_company_id" class="form-control form-control-sm">
                  <option selected disabled :value="''">Select cleaning company</option>
                  <option v-for="(cleaning_company, index) in cleaning_companies" :key="index" :value="cleaning_company.id">{{ cleaning_company.name }}</option>
                </select>
                <div class="invalid-feedback">Cleaning company is required</div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-6 col-sm-6 col-lg-6">
                <label for="no_of_cleaners" class="col-form-label col-form-label-sm">No. of Cleaners <span class="custom-required">*</span></label>
                <input type="text" v-model="hub.no_of_cleaners" v-validate="'required|numeric'" id="no_of_cleaners" name="no_of_cleaners" class="form-control form-control-sm" placeholder="Enter np. of cleaners"/>
                <div class="invalid-feedback">No. of cleaners is required</div>
              </div>
              <div class="col-md-6 col-sm-6 col-lg-6">
                <label for="security_company_id" class="col-form-label col-form-label-sm">Security Company <span class="custom-required">*</span></label>
                <select v-model="hub.security_company_id" v-validate="'required'" id="security_company_id" name="security_company_id" class="form-control form-control-sm">
                  <option selected disabled :value="''">Select security company</option>
                  <option v-for="(security_company, index) in security_companies" :key="index" :value="security_company.id">{{ security_company.name }}</option>
                </select>
                <div class="invalid-feedback">Security company is required</div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-6 col-sm-6 col-lg-6">
                <label for="no_of_security" class="col-form-label col-form-label-sm">No. of Security <span class="custom-required">*</span></label>
                <input type="text" v-model="hub.no_of_security" v-validate="'required|numeric'" id="no_of_security" name="no_of_security" class="form-control form-control-sm" placeholder="Enter np. of security"/>
                <div class="invalid-feedback">No. of security is required</div>
              </div>
              <div class="col-md-6 col-sm-6 col-lg-6">
                <label for="location_sft" class="col-form-label col-form-label-sm">Location SFT <span class="custom-required">*</span></label>
                <input type="text" v-model="hub.location_sft" v-validate="'required|numeric|max:10'" id="location_sft" name="location_sft" class="form-control form-control-sm" placeholder="Enter location sft"/>
                <div class="invalid-feedback">Location SFT is required</div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-12 col-sm-12 col-lg-12">
                <label for="departments" class="col-form-label col-form-label-sm">Department Working <span class="custom-required">*</span></label>
                <v-select v-model="hub.departments" multiple :class="errors.first('departments') ? 'borderColor' : ''" name="departments" id="departments" v-validate="'required'" label="department_name" :options="departments"></v-select>
                <div v-if="errors.first('departments')" class="error">Department working is required</div>
              </div>
            </div>
          <div class="form-row mt-3 mb-3">
            <div class="col-md-6 col-sm-6 col-lg-6">
              <a-button type="primary" class="text-center mr-2" :loading="loader"  @click.prevent="update">Update</a-button>
              <a-button type="danger" class="text-center" @click="$bvModal.hide('hub-update-modal')">Cancel</a-button>
            </div>
          </div>
        </div>
        </form>
      </b-modal>
    </div>
  </div>
</template>
<script>
import apiClient from '@/services/axios'
import moment from 'moment'
import Datepicker from 'vuejs-datepicker'

export default {
  name: 'index',
  components: { Datepicker },
  data() {
    return {
      departments: [],
      cleaning_companies: [],
      security_companies: [],
      hub: {
        hub_employees: '',
        location_sft: '',
        departments: '',
        rent_agreement_expiration: '',
        trade_license_expiration: '',
        dife_license_expiration: '',
        fire_license_expiration: '',
      },
      validation_errors: {},
      loader: false,
      show: false,
      disabledDates: {
        to: moment().subtract(1, 'days').toDate(),
      },
    }
  },
  mounted() {
    this.getCode()
    this.getHub()
  },
  methods: {
    customRentAgreementDateFormat(date) {
      this.hub.rent_agreement_expiration = moment(date).format('YYYY-MM-DD')
      return moment(date).format('YYYY-MM-DD')
    },
    getCode() {
      apiClient.get('api/hub/codes')
        .then(response => {
          const data = response.data
          this.departments = data.departments
          this.cleaning_companies = data.cleaning_companies
          this.security_companies = data.security_companies
        })
        .catch(error => {
          console.log(error)
        })
    },
    getHub() {
      this.loading = true
      apiClient.get('api/user/hub').then(response => {
        this.loading = false
        if (!response.data.error) {
          this.hub = response.data.hub
        } else {
          this.$notification.error({
            message: response.data.message,
          })
        }
      })
        .catch(error => {
          console.log(error)
        })
    },
    update() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.loader = true
          apiClient.post('api/user/hub/' + this.hub.id, this.hub)
            .then(response => {
              if (!response.data.error) {
                this.loader = false
                this.$bvModal.hide('hub-update-modal')
                this.$emit('getUser')
                this.$notification.success({
                  message: response.data.message,
                })
              } else {
                this.loader = false
                this.$notification.error({
                  message: 'Update Failed',
                })
              }
            })
            .catch(error => {
              this.loader = false
              this.show = true
              this.validation_errors = error.response.data.errors
              this.hide()
            })
        }
      })
    },
    hide() {
      setTimeout(() => {
        this.show = false
      }, 5000)
    },
  },
}
</script>

<style>
.error{
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.borderColor{
  border: 1px solid red !important;
  border-radius:4px !important;
}
</style>
