<template>
  <div>
    <div class="mb-5">
      <b-modal id="profile-update-modal" title="Profile Update" size="lg" hide-footer>
        <div v-show="show" class="alert alert-danger alert-dismissible fade show" role="alert">
          <span v-for="(err, index) in validation_errors" :key="index">{{ err[0] }} <br/></span>
        </div>
        <form action="" enctype="multipart/form-data" files="true" role="form">
          <div class="modal-content bg-white rounded">
          <div class="form-row">
            <div class="col-md-6 col-sm-6 col-lg-6">
              <label for="name" class="col-form-label col-form-label-sm">Name <span class="custom-required">*</span></label>
              <input type="text" v-model="name" v-validate="'required'" id="name" name="name" class="form-control form-control-sm" placeholder="Enter user name"/>
              <div class="invalid-feedback">Name is required</div>
            </div>
            <div class="col-md-6 col-sm-6 col-lg-6">
              <label for="email" class="col-form-label col-form-label-sm">Email <span class="custom-required">*</span></label>
              <input type="text" readonly disabled v-model="email" v-validate="'required'" id="email" name="email" class="form-control form-control-sm" placeholder="Enter email address"/>
              <div class="invalid-feedback">Email is required</div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-6 col-sm-6 col-lg-6">
              <label for="phone_no" class="col-form-label col-form-label-sm">Phone Number <span class="custom-required">*</span></label>
              <input type="number" v-model="phone_no" v-validate="{ required: true }" id="phone_no" name="phone_no" class="form-control form-control-sm" placeholder="Enter phone number"/>
              <div class="invalid-feedback">Phone number is required</div>
            </div>
            <div class="col-md-6 col-sm-6 col-lg-6">
              <label for="office_id" class="col-form-label col-form-label-sm">Office ID</label>
              <input type="number" v-model="office_id" v-validate="{  max: 8 }" id="office_id" name="office_id" class="form-control form-control-sm" placeholder="Enter office id"/>
              <div class="invalid-feedback">Office ID is invalid</div>
            </div>
          </div>
            <div class="form-row">
              <div class="col-md-6 col-sm-6 col-lg-6">
                <label for="update_profile_picture" class="col-form-label col-form-label-sm">Profile Picture <span style="font-weight: 100;">*Previous file <a :href="profile_picture" target="_blank">click here</a></span></label>
                <input type="file" ref="profilePicture" id="update_profile_picture" name="update_profile_picture" @change="getProfilePicture()" accept=".jpg,.jpeg,.png" class="form-control form-control-sm"/>
              </div>
            </div>
          <div class="form-row mt-3 mb-3">
            <div class="col-md-6 col-sm-6 col-lg-6">
              <a-button type="primary" class="text-center mr-2" :loading="loader"  @click.prevent="update">Update</a-button>
              <a-button type="danger" class="text-center" @click="$bvModal.hide('profile-update-modal')">Cancel</a-button>
            </div>
          </div>
        </div>
        </form>
      </b-modal>
    </div>
  </div>
</template>
<script>
import apiClient from '@/services/axios'

export default {
  name: 'index',
  components: { },
  data() {
    return {
      userId: '',
      name: '',
      email: '',
      phone_no: '',
      office_id: '',
      update_profile_picture: '',
      profile_picture: '',
      validation_errors: {},
      loader: false,
      show: false,
    }
  },
  mounted() { },
  methods: {
    onUpdate(userId) {
      this.loading = true
      apiClient.get('api/users/' + userId + '/edit').then(response => {
        this.loading = false
        if (!response.data.error) {
          const data = response.data.update_user
          this.userId = data.id
          this.name = data.name
          this.email = data.email
          this.phone_no = data.phone_no
          this.office_id = data.office_id
          this.profile_picture = data.profile_picture
        } else {
          this.$notification.error({
            message: response.data.message,
          })
        }
      })
        .catch(error => {
          console.log(error)
        })
    },
    update() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.loader = true
          const formData = new FormData()
          formData.append('name', this.name)
          formData.append('email', this.email)
          formData.append('phone_no', this.phone_no)
          formData.append('office_id', this.office_id)
          formData.append('profile_picture', this.update_profile_picture)
          apiClient.post('api/user/profile/update', formData, { headers: { 'Content-Type': 'multipart/form-data', 'Content-type': 'application/json' } }).then(response => {
            this.loader = false
            this.$bvModal.hide('profile-update-modal')
            this.$emit('getUser')
            this.$notification.success({
              message: response.data.message,
            })
          }).catch(error => {
            this.loader = false
            this.validation_errors = error.response.data.errors
            this.show = true
            this.hide()
          })
        }
      })
    },
    getProfilePicture() {
      this.update_profile_picture = this.$refs.profilePicture.files[0]
      if (this.update_profile_picture.size > 3072000) {
        this.$notification.error({
          message: 'File size not greater then 3 MB',
        })
        this.update_profile_picture = ''
      }
    },
    hide() {
      setTimeout(() => {
        this.show = false
      }, 5000)
    },
  },
}
</script>

<style>

</style>
